import { template as template_6282ac4082ae4d0a8736e449ad7f119c } from "@ember/template-compiler";
const WelcomeHeader = template_6282ac4082ae4d0a8736e449ad7f119c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
