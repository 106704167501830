import { template as template_05d0c6f79a9843a0b1eea99d5ddf554a } from "@ember/template-compiler";
const SidebarSectionMessage = template_05d0c6f79a9843a0b1eea99d5ddf554a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
